import React from "react"

import InstallFrom from "./installFrom"
import SlideShow from "./slideshow"

const Home = () => (
  <>
    <section className="text-description">
      <p>
        Box-scores is a free browser extension for getting live NBA scores,
        play-by-play, standings, post game highlights and more.
      </p>
      <p>
        Simply click on an extension and you will see the live score for the
        day.
      </p>
      <p>
        This is an extension that allows basketball enthusiast to enjoy the game
        without the pain to search match statues online. Gives you detailed box
        scores with a single click!
      </p>
    </section>
    <SlideShow />
    <section className="install-links">
      <div className="install-links-container">
        <InstallFrom browser="chrome" />
        <InstallFrom browser="firefox" />
        <InstallFrom browser="opera" />
      </div>
    </section>
  </>
)

export default Home

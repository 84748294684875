import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Chrome from "./icons/chrome"
import Firefox from "./icons/firefox"
import Opera from "./icons/opera"

const browsers = ["chrome", "firefox", "opera"]

const InstallFrom = ({ browser }) => {
  if (!browsers.includes(browser)) {
    return <p>{browser}</p>
  }

  switch (browser) {
    case "chrome":
      return (
        <a
          className="install-link"
          target="_blank"
          rel="noreferrer"
          href="https://chrome.google.com/webstore/detail/basketball-box-scores/mfmkedeaebcckihpinmhkadoagdbifaa"
        >
          <Chrome />
          <p className="install-link-text">{`Install on ${browser}`}</p>
        </a>
      )
    case "firefox":
      return (
        <a
          className="install-link"
          target="_blank"
          rel="noreferrer"
          href="https://addons.mozilla.org/en-CA/firefox/addon/basketball-box-scores/"
        >
          <Firefox />
          <p className="install-link-text">{`Install on ${browser}`}</p>
        </a>
      )
    case "opera":
      return (
        <Link className="install-link" to="/opera">
          <Opera />
          <p className="install-link-text">{`Install on ${browser}`}</p>
        </Link>
      )
    default:
      throw new Error()
  }
}

InstallFrom.propTypes = {
  browser: PropTypes.string,
}

export default InstallFrom

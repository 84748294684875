import React, { useState, useEffect, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

const SlideShow = () => {
  const [index, setIndex] = useState(0)
  const { allFile } = useStaticQuery(
    graphql`
      query {
        allFile(
          sort: { fields: name, order: ASC }
          filter: { relativeDirectory: { eq: "screenshots" } }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    `
  )
  const indexRef = useRef(index)
  indexRef.current = index

  const length = allFile.edges.length - 1
  useEffect(() => {
    const timer = setInterval(() => {
      setIndex((indexRef.current + 1) % length)
    }, 5000)
    return () => clearTimeout(timer)
  }, [indexRef, length])

  const { node } = allFile.edges[index]
  return (
    <section className="screenshots">
      <Img
        fluid={node.childImageSharp.fluid}
        key={node.id}
        alt={node.name.replace(/-/g, " ").substring(2)}
        objectFit="scale-down"
      />
    </section>
  )
}

export default SlideShow
